import { ContentTypeName } from '@/constants';
import { dataSectionQuery, rightSectionList } from '@/fetchers';
import {
  type IDataSection,
  type IThumbnailCarousel,
  type ITradelane,
  type ITradelanePageSlugSplit,
  KeysCanBeCompared,
  SectionItemType,
  type ThumbnailProps,
} from '@/interfaces';
import { getCountryBySlug, mapChartQueryOutput, minifyQuery, pageDataModify, replaceHighlightString } from '@/utils';
import callPmAnalytics from '../pmAnalyticsAPI/base.fetchers';
import { needEventOrArticleData, utils_getEventThumbnailsByDate, utils_getThumbnailsByTag } from '../utils';
import callContentful from './base.fetchers';

const THUMBNAIL_CAROUSEL_MAX_ITEMS = 30;

const TRADELANE_NAME = 'tradelane';
const TRADELANE_SLUG = 'tradelane_slug';

type GetTradelanePage = (args: {
  isPreviewMode: boolean;
  slug: string;
  allEventsList: ThumbnailProps[];
  allArticlesList: ThumbnailProps[];
  accessToken?: string;
  shouldGetMasterEntry: boolean;
  slugSplit: ITradelanePageSlugSplit;
}) => Promise<ITradelane | null>;

export const getTradelanePage: GetTradelanePage = async ({
  isPreviewMode,
  slug,
  allEventsList,
  allArticlesList,
  accessToken,
  shouldGetMasterEntry = false,
  slugSplit,
}) => {
  const queryFilter = shouldGetMasterEntry ? 'tradelanePool_exists: false' : `tradelanePool_contains_some: ["${slug}"]`;

  const query = /* GraphQL */ `
    {
      tradelaneCollection(
        where: {
          ${queryFilter}
        }
        preview: ${isPreviewMode}
        limit: 1
      ) {
        items {
          sys {
            id
          }
          pageTitle
          breadcrumbsTheme
          sectionList: sectionListCollection(
            preview: ${isPreviewMode}
            limit: 11
          ) {
            items {
              __typename
              ... on LeadGenBanner {
                title
                titleMobile
                image {
                  contentType
                  url(transform: { width: 696, resizeStrategy: FIT })
                  description
                }
                imageMobile {
                  contentType
                  url(transform: { width: 696, resizeStrategy: FIT })
                  description
                }
                subCopy
                subCopyMobile
                buttons: buttonsCollection(limit: 3) {
                  items {
                    sys {
                      id
                    }
                    primaryButtonText
                    secondaryButtonText
                    function
                    color
                    email
                    buttonLink
                    buttonEventLabel
                    openInNewTab
                  }
                }
                sectionTheme: theme
                isLinkedInBanner
              }
              ... on SeoSection {
                title
                seoList
                width
                longForm {
                  json
                }
              }
              ... on ThumbnailCarousel {
                tag {
                  title
                  type
                  slug
                  hide
                }
                itemType
              }
              ${dataSectionQuery(isPreviewMode)}
              ... on LeadsWrapper {
                title
                theme
                backgroundImage {
                  contentType
                  url
                  description
                }
                form {
                  sys {
                    id
                  }
                  title
                  subText
                  button {
                    primaryButtonText
                    secondaryButtonText
                    function
                    color
                    buttonLink
                    openInNewTab
                  }
                  formFieldList: formFieldListCollection(
                    preview: ${isPreviewMode}
                    limit: 10
                  ) {
                    items {
                      ... on FormField {
                        name
                        label
                        placeholder
                        isMandatory
                        type
                        width
                        mandatoryCheckErrorMessage
                        customErrorMessage
                        dropdownOptions
                      }
                    }
                  }
                }
                valuePropositionList: valuePropositionListCollection(
                  preview: ${isPreviewMode}
                  limit: 3
                ) {
                  items {
                    sys {
                      id
                    }
                    title
                    contentType
                    url
                    description
                  }
                }
                customerLogoTitle
                customerLogoList: customerLogoListCollection(
                  preview: ${isPreviewMode}
                  limit: 8
                ) {
                  items {
                    sys {
                      id
                    }
                    contentType
                    url
                    description
                  }
                }
                zapierHookId
              }
              ... on PageOverviewBanner {
                title
                titleMobile
                subTitle
                subText
                subTextMobile
                buttons: buttonsCollection(limit: 3) {
                  items {
                    sys {
                      id
                    }
                    primaryButtonText
                    secondaryButtonText
                    function
                    color
                    buttonLink
                    email
                    openInNewTab
                    hideOnLoggedIn
                  }
                }
                theme
                image {
                  contentType
                  url
                  description
                }
                imageMobile {
                  contentType
                  url
                  description
                }
                loginTitle
                loginMobileTitle
                loginSubtext
                widget
              }
            }
          }
          seo {
            title
            description
            ogType
            ogUrl
            ogImage {
              contentType
              url
            }
            ogTitle
            ogDescription
            robots
          }
          ${rightSectionList(isPreviewMode)}
        }
      }
    }
  `;

  const response = await callContentful(minifyQuery(query), isPreviewMode, slug, 'tradelane.fetchers.getTradelanePage');
  const pageData = pageDataModify<ITradelane>(response.data?.tradelaneCollection?.items?.filter(Boolean)?.[0]);

  if (!pageData) return null;

  const originCountry = getCountryBySlug(slugSplit.originSlug);
  const destinationCountry = getCountryBySlug(slugSplit.destinationSlug);
  const tradelaneName = `${originCountry?.marketName} to ${destinationCountry?.marketName}`;

  pageData.pageTitle = replaceHighlightString(pageData.pageTitle, TRADELANE_NAME, tradelaneName);

  pageData.seo.title = replaceHighlightString(pageData.seo.title, TRADELANE_NAME, tradelaneName);
  pageData.seo.description = replaceHighlightString(pageData.seo.description, TRADELANE_NAME, tradelaneName);
  pageData.seo.ogTitle = replaceHighlightString(pageData.seo.ogTitle, TRADELANE_NAME, tradelaneName);
  pageData.seo.ogDescription = replaceHighlightString(pageData.seo.ogDescription, TRADELANE_NAME, tradelaneName);
  pageData.seo.ogUrl = replaceHighlightString(pageData.seo.ogUrl, TRADELANE_SLUG, slug);

  const foundThumbnailCarousel = pageData.sectionList?.filter(
    (section) => section.__typename === ContentTypeName.ThumbnailCarousel,
  ) as IThumbnailCarousel[];

  const foundDataSection = pageData.sectionList?.filter(
    (section) => section.__typename === ContentTypeName.CarrierSection,
  ) as IDataSection[];

  const { hasEvents, hasArticles } = needEventOrArticleData({
    foundThumbnailCarousel,
  });

  const allEvents = hasEvents ? allEventsList || [] : [];
  const allArticles = hasArticles ? allArticlesList || [] : [];

  if (foundThumbnailCarousel.length > 0) {
    foundThumbnailCarousel.map((item) => {
      const el =
        item.itemType === SectionItemType.Event
          ? utils_getEventThumbnailsByDate({
              events: allEvents,
              slug: item.tag.slug,
              type: item.tag.type,
            })
          : utils_getThumbnailsByTag({
              articles: allArticles,
              type: item.tag.type,
              slug: item.tag.slug,
            });
      item.thumbnails = el.slice(0, THUMBNAIL_CAROUSEL_MAX_ITEMS);
    });
  }

  if (foundDataSection.length > 0) {
    await Promise.all(
      foundDataSection.map(async (dataSection) => {
        dataSection.title = replaceHighlightString(dataSection.title ?? '', TRADELANE_NAME, tradelaneName);
        if (dataSection.chartList?.length) {
          dataSection.chartList = await Promise.all(
            (dataSection.chartList || []).map(async (chart) => {
              const query = mapChartQueryOutput(chart.type, chart.query);
              const analyticsQuery = {
                ...query,
                input: [
                  ...query.input,
                  {
                    name: KeysCanBeCompared.tradelane,
                    value: `${originCountry?.countryCode} TO ${destinationCountry?.countryCode}`,
                  },
                ],
              };
              return {
                ...chart,
                query,
                data: await callPmAnalytics({
                  query: analyticsQuery,
                  accessToken,
                }),
              };
            }),
          );
        }
      }),
    );
  }

  return pageData;
};
