import { ContentTypeName, PageType } from '@/constants';
import {
  dataSectionQuery,
  rightSectionList,
  tagsAndMarketsQuery,
  thumbnailSectionQuery,
  wordCloudQuery,
} from '@/fetchers';
import {
  HighlightDisplayLogic,
  type IChart,
  type IHighlightSection,
  type IMarket,
  type IThumbnailCarousel,
  type IThumbnailSection,
  SectionItemType,
  type ThumbnailProps,
} from '@/interfaces';
import { generateQueryForMarket, mapChartQueryOutput, minifyQuery, pageDataModify } from '@/utils';
import callPmAnalytics from '../pmAnalyticsAPI/base.fetchers';
import {
  needEventOrArticleData,
  utils_getEventThumbnailsByDate,
  utils_getTagPageThumbnailSectionData,
  utils_getThumbnailsByTag,
  utils_getThumbnailsByTagList,
} from '../utils';
import callContentful from './base.fetchers';

const THUMBNAIL_CAROUSEL_MAX_ITEMS = 30;

type GetMarketPage = (args: {
  isPreviewMode: boolean;
  slug?: string;
  allEventsList: ThumbnailProps[];
  allArticlesList: ThumbnailProps[];
  accessToken?: string;
}) => Promise<IMarket | null>;

export const getMarketPage: GetMarketPage = async ({
  isPreviewMode,
  slug,
  allEventsList,
  allArticlesList,
  accessToken,
}) => {
  const query = /* GraphQL */ `
    {
      marketCollection(
        preview: ${isPreviewMode}
        where: {
          ${slug ? `slug: "${slug}"` : 'slug_exists: false'}
        }
        limit: 1
      ) {
        items {
          sys { id }
          slug
          breadcrumbsTheme
          marketName
          countryCode
          subTitle
          seoDescription {
            json
          }
          region
          sectionList: sectionListCollection(
            preview: ${isPreviewMode}
            limit: 11
          ) {
            items {
              __typename
              ... on LeadsWrapper {
                title
                theme
                backgroundImage {
                  contentType
                  url
                  description
                }
                form {
                  sys {
                    id
                  }
                  title
                  subText
                  button {
                    primaryButtonText
                    secondaryButtonText
                    function
                    color
                    buttonLink
                    openInNewTab
                  }
                  formFieldList: formFieldListCollection(
                    preview: ${isPreviewMode}
                    limit: 10
                  ) {
                    items {
                      ... on FormField {
                        name
                        label
                        placeholder
                        isMandatory
                        type
                        width
                        mandatoryCheckErrorMessage
                        customErrorMessage
                        dropdownOptions
                      }
                    }
                  }
                }
                valuePropositionList: valuePropositionListCollection(
                  preview: ${isPreviewMode}
                  limit: 3
                ) {
                  items {
                    sys {
                      id
                    }
                    title
                    contentType
                    url
                    description
                  }
                }
                customerLogoTitle
                customerLogoList: customerLogoListCollection(
                  preview: ${isPreviewMode}
                  limit: 8
                ) {
                  items {
                    sys {
                      id
                    }
                    contentType
                    url
                    description
                  }
                }
                zapierHookId
              }
              ... on HighlightSection {
                title
                displayingLogic
                tags: tagsCollection(limit: 5) {
                  items {
                    sys {
                      id
                    }
                    title
                    type
                    slug
                  }
                }
              }
              ${wordCloudQuery()}
              ... on SeoSection {
                title
                seoList
                width
                longForm {
                  json
                }
              }
              ... on ThumbnailCarousel {
                tag {
                  title
                  type
                  slug
                  hide
                }
                itemType
              }
              ... on ThumbnailSection {
                ${thumbnailSectionQuery()}
              }
              ... on LeadGenBanner {
                title
                titleMobile
                image {
                  contentType
                  url(transform: {
                    width: 1261,
                    resizeStrategy: FIT,
                  })
                  description
                }
                imageMobile {
                  contentType
                  url(transform: {
                    width: 696,
                    resizeStrategy: FIT,
                  })
                  description
                }
                subCopy
                subCopyMobile
                buttons: buttonsCollection(limit: 3) {
                  items {
                    sys {
                      id
                    }
                    primaryButtonText
                    secondaryButtonText
                    function
                    color
                    email
                    buttonLink
                    buttonEventLabel
                    openInNewTab
                  }
                }
                sectionTheme: theme
                isLinkedInBanner
              }
              ... on PageOverviewBanner {
                title
                titleMobile
                subTitle
                subText
                subTextMobile
                buttons: buttonsCollection(limit: 3) {
                  items {
                    sys {
                      id
                    }
                    primaryButtonText
                    secondaryButtonText
                    function
                    color
                    buttonLink
                    email
                    openInNewTab
                    hideOnLoggedIn
                  }
                }
                theme
                image {
                  contentType
                  url
                  description
                }
                imageMobile {
                  contentType
                  url
                  description
                }
                loginTitle
                loginMobileTitle
                loginSubtext
                widget
              }
              ... on EventList {
                title
                featured
                eventList: eventListCollection(limit: 4) {
                  items {
                    sys { 
                      id 
                    }
                    title
                    slug
                    image {
                      contentType
                      url(transform: {
                        height: 300,
                        resizeStrategy: FIT,
                      })
                    }
                    startDate
                    endDate
                    address
                    seo {
                      description
                    }
                    tags: tagsCollection(limit: 2) {
                      items {
                        ${tagsAndMarketsQuery()}
                      }
                    }
                  }
                }
              }
              ${dataSectionQuery(isPreviewMode)}
            }
          }
          seo {
            title
            description
            ogType
            ogUrl
            ogImage {
              contentType
              url
            }
            ogTitle
            ogDescription
            robots
          }
          ${rightSectionList(isPreviewMode)}
        }
      }
    }
  `;

  const response = await callContentful(
    minifyQuery(query),
    isPreviewMode,
    `/markets/${slug || ''}`,
    'market.fetchers.getMarketPage',
  );
  const pageData = response.data?.marketCollection?.items?.filter(Boolean)?.[0];
  const sectionItems = pageData?.sectionList?.items?.filter(Boolean);

  if (!pageData) return null;

  const foundHighlightSection: IHighlightSection = sectionItems?.find(
    (section: any) => section.__typename === ContentTypeName.HighlightSection,
  );

  const foundThumbnailCarousel: IThumbnailCarousel[] = sectionItems?.filter(
    (section: any) => section.__typename === ContentTypeName.ThumbnailCarousel,
  ) as IThumbnailCarousel[];

  const foundThumbnailSection: IThumbnailSection = sectionItems?.find(
    (section: any) => section.__typename === ContentTypeName.ThumbnailSection,
  );

  const foundDataSection: any[] = sectionItems?.filter(
    (section: any) => section.__typename === ContentTypeName.CarrierSection,
  );

  const { hasEvents, hasArticles } = needEventOrArticleData({
    foundHighlightSection,
    foundThumbnailCarousel,
    foundThumbnailSection,
  });

  const allEvents = hasEvents ? allEventsList || [] : [];
  const allArticles = hasArticles ? allArticlesList || [] : [];

  if (foundHighlightSection) {
    const filteredTags = foundHighlightSection.tags?.items?.filter(Boolean);
    let highlightSectionThumbnails: ThumbnailProps[] = [];

    if (!filteredTags || filteredTags.length === 0) {
      highlightSectionThumbnails = utils_getThumbnailsByTag({
        isFeatured: foundHighlightSection.displayingLogic === HighlightDisplayLogic.Featured,
        articles: allArticles,
        slug: pageData.slug,
      });
    } else {
      highlightSectionThumbnails = utils_getThumbnailsByTagList({
        isFeatured: foundHighlightSection.displayingLogic === HighlightDisplayLogic.Featured,
        articles: allArticles,
        tags: filteredTags,
      });
    }

    foundHighlightSection.thumbnails = highlightSectionThumbnails.slice(0, 4);
  }

  if (foundThumbnailCarousel.length > 0) {
    foundThumbnailCarousel.map((item) => {
      const el =
        item.itemType === SectionItemType.Event
          ? utils_getEventThumbnailsByDate({
              events: allEvents,
              slug: item.tag.slug,
            })
          : utils_getThumbnailsByTag({
              articles: allArticles,
              slug: item.tag.slug,
            });
      item.thumbnails = el.slice(0, THUMBNAIL_CAROUSEL_MAX_ITEMS);
    });
  }

  if (foundDataSection.length > 0) {
    await Promise.all(
      foundDataSection.map(async (dataSection) => {
        dataSection.chartList.items = await Promise.all(
          (dataSection.chartList?.items || []).map(async (chart: IChart) => {
            const query = mapChartQueryOutput(chart.type, chart.query);

            return {
              ...chart,
              query,
              data: await callPmAnalytics({
                query: generateQueryForMarket(query, pageData),
                accessToken,
              }),
            };
          }),
        );
      }),
    );
  }

  if (foundThumbnailSection) {
    foundThumbnailSection.latestData = utils_getTagPageThumbnailSectionData({
      foundThumbnailSection,
      foundHighlightSection,
      allThumbnailList: allArticles,
      allEventList: allEvents,
      tagDataSlug: pageData.slug,
    });

    foundThumbnailSection.foundHighlightSection = foundHighlightSection || null;
    foundThumbnailSection.pageType = PageType.MarketPage;
    foundThumbnailSection.tagDataSlug = pageData.slug;
  }

  return pageData ? pageDataModify(pageData) : null;
};
