export const rightSectionList = (isPreviewMode: boolean): string => {
  return `
    rightSectionList: rightSectionListCollection(limit: 7, preview: ${isPreviewMode}) {
      items {
        sys {
          id
        }
        title
        type
        button {
          primaryButtonText
          secondaryButtonText
          function
          color
          email
          buttonLink
          openInNewTab
          triggerPopUp
        }
        list: listCollection(limit: 5, preview: ${isPreviewMode}) {
          items {
            __typename
            ... on Article {
              title
              slug
              articleType
              date
              image {
                url(transform: {
                  width: 468,
                  resizeStrategy: FIT,
                })
                description
                contentType
              }
              seo {
                description
              }
            }
            ... on Carrier {
              carrierDisplayName
              slug
              featured
              carrierLogo {
                url(transform: {
                  width: 114,
                  resizeStrategy: FIT,
                })
                description
                contentType
              }
            }
            ... on LeadGenBanner {
              title
              image {
                contentType
                url(transform: {
                  width: 528,
                  resizeStrategy: FIT,
                })
                description
              }
              subCopy
              buttons: buttonsCollection(limit: 3, preview: ${isPreviewMode}) {
                items {
                  sys {
                    id
                  }
                  primaryButtonText
                  secondaryButtonText
                  function
                  color
                  email
                  buttonLink
                  buttonEventLabel
                  openInNewTab
                }
              }
              sectionTheme: theme
              isLinkedInBanner
            }
          }
        }
      }
    }
  `;
};

export const marketMinifiedQuery = (): string => {
  return `
    sys {
      id
    }
    slug
    marketName
    subTitle
    countryCode
    region
  `;
};

export const tagsAndMarketsQuery = (): string => {
  return `
    __typename
    ... on Tag {
      sys { id }
      title
      type
      slug
      category
    }
    ... on Market {
      ${marketMinifiedQuery()}
    }
  `;
};

export const thumbnailSectionQuery = (tagsLimit = 5, allowFeaturedArticles = false): string => {
  let featuredArticlesQuery = '';

  if (allowFeaturedArticles) {
    const FEATURED_ARTICLES_LIMIT = 6;

    featuredArticlesQuery = `
      featuredArticles: featuredArticlesCollection(limit: ${FEATURED_ARTICLES_LIMIT}) {
        items {
          sys {
            id
          }
          title
          articleType
          slug
          featured
          date
          image {
            contentType
            url
            title
            description
          }
          seo {
            description
          }
        }
      }
    `;
  }

  return `
    mostRead
    itemType
    articleType
    containsMarketTag
    buttonLink
    tags: tagsCollection(limit: ${tagsLimit}) {
      items {
        __typename
        sys {
          id
        }
        title
        type
        slug
      }
    }
    ${featuredArticlesQuery}
  `;
};

export const pageOverviewBannerQuery = () => {
  return `
    ... on PageOverviewBanner {
      title
      titleMobile
      subTitle
      subText
      subTextMobile
      buttons: buttonsCollection(limit: 3) {
        items {
          sys {
            id
          }
          primaryButtonText
          secondaryButtonText
          function
          color
          buttonLink
          email
          openInNewTab
          hideOnLoggedIn
        }
      }
      theme
      image {
        contentType
        url
        description
      }
      imageMobile {
        contentType
        url
        description
      }
      heroAsset {
        contentType
        url
        description
      }
      loginTitle
      loginMobileTitle
      loginSubtext
      widget
    }
  `;
};

export const wordCloudQuery = (): string => {
  return `
    ... on WordCloud {
      alignment
      title
      subText
      layout
      buttonLink
      wordBubbles: wordBubblesCollection {
        items {
          ${tagsAndMarketsQuery()}
        }
      }
    }
  `;
};

// NOTE: Landing page does not have Chart List logic
export const dataSectionQuery = (isPreviewMode: boolean): string => {
  return `
    ... on CarrierSection {
      uiTemplate
      title
      desktopSubTitle
      mobileSubTitle
      sectionLabel
      carrierListTitle
      retailerCompetitorListTitle
      chartList: chartListCollection(
        preview: ${isPreviewMode}
        limit: 5
      ) {
        items {
          title
          showDescription
          type
          minimumRecord
          query
          registrationWall {
            __typename
            type
            sectionHeader
            image {
              contentType
              url(transform: { width: 520, resizeStrategy: FIT })
              description
            }
            mobileImage {
              contentType
              url(transform: { width: 520, resizeStrategy: FIT })
              description
            }
            title
            subTitle
            button {
              buttonLink
              buttonEventLabel
              primaryButtonText
              function
              email
            }
            subText
          }
        }
      }
      peopleList: peopleListCollection(
        preview: ${isPreviewMode}
        limit: 6
      ) {
        items {
          image {
            contentType
            url(transform: { width: 130, resizeStrategy: FIT })
            description
          }
          fullName
          jobPosition
          profileLink
        }
      }
      carrierList: carrierListCollection(
        preview: ${isPreviewMode}
        limit: 6
      ) {
        items {
          sys {
            id
          }
          slug
          carrierLogo {
            contentType
            url
            description
          }
          carrierName
          carrierDisplayName
          featured
          headquarters
          featured
        }
      }
      wordCloud {
        title
        subText
        layout
        buttonLink
        wordBubbles: wordBubblesCollection {
          items {
            ${tagsAndMarketsQuery()}
          }
        }
      }
      referenceId
      targetId
      buttonLabel
    }
  `;
};
