import { ContentTypeName } from '@/constants';
import { dataSectionQuery, rightSectionList } from '@/fetchers';
import type { ICompanyDirectory, IDataSection } from '@/interfaces';
import { mapChartQueryOutput, minifyQuery, pageDataModify } from '@/utils';
import callPmAnalytics from '../pmAnalyticsAPI/base.fetchers';
import callContentful from './base.fetchers';

type GetCompanyDirectoryPage = (args: {
  isPreviewMode: boolean;
  slug?: string;
  accessToken?: string;
}) => Promise<ICompanyDirectory | null>;

export const getCompanyDirectoryPage: GetCompanyDirectoryPage = async ({ isPreviewMode, slug, accessToken }) => {
  const query = /* GraphQL */ `
    {
      companyDirectoryCollection(
        where: {
          slug: "${slug}"
        }
        preview: ${isPreviewMode}
        limit: 1
      ) {
        items {
          sys {
            id
          }
          title
          breadcrumbsTheme
          icon {
            contentType
            url(transform: { width: 32, resizeStrategy: FIT })
            description
          }
          slug
          subTitle: desktopSubTitle
          sectionList: sectionListCollection(
            preview: ${isPreviewMode}
            limit: 11
          ) {
            items {
              __typename
              ... on LeadGenBanner {
                title
                titleMobile
                image {
                  contentType
                  url(transform: { width: 696, resizeStrategy: FIT })
                  description
                }
                imageMobile {
                  contentType
                  url(transform: { width: 696, resizeStrategy: FIT })
                  description
                }
                subCopy
                subCopyMobile
                buttons: buttonsCollection(limit: 3) {
                  items {
                    sys {
                      id
                    }
                    primaryButtonText
                    secondaryButtonText
                    function
                    color
                    email
                    buttonLink
                    buttonEventLabel
                    openInNewTab
                  }
                }
                sectionTheme: theme
                isLinkedInBanner
              }
              ... on SeoSection {
                title
                seoList
                width
                longForm {
                  json
                }
              }
              ... on PageOverviewBanner {
                title
                titleMobile
                subTitle
                subText
                subTextMobile
                buttons: buttonsCollection(limit: 3) {
                  items {
                    sys {
                      id
                    }
                    primaryButtonText
                    secondaryButtonText
                    function
                    color
                    buttonLink
                    email
                    openInNewTab
                    hideOnLoggedIn
                  }
                }
                theme
                image {
                  contentType
                  url
                  description
                }
                imageMobile {
                  contentType
                  url
                  description
                }
                loginTitle
                loginMobileTitle
                loginSubtext
              }
              ${dataSectionQuery(isPreviewMode)}
              ... on LeadsWrapper {
                title
                theme
                backgroundImage {
                  contentType
                  url
                  description
                }
                form {
                  sys {
                    id
                  }
                  title
                  subText
                  button {
                    primaryButtonText
                    secondaryButtonText
                    function
                    color
                    buttonLink
                    openInNewTab
                  }
                  formFieldList: formFieldListCollection(
                    preview: ${isPreviewMode}
                    limit: 10
                  ) {
                    items {
                      ... on FormField {
                        name
                        label
                        placeholder
                        isMandatory
                        type
                        width
                        mandatoryCheckErrorMessage
                        customErrorMessage
                        dropdownOptions
                      }
                    }
                  }
                }
                valuePropositionList: valuePropositionListCollection(
                  preview: ${isPreviewMode}
                  limit: 3
                ) {
                  items {
                    sys {
                      id
                    }
                    title
                    contentType
                    url
                    description
                  }
                }
                customerLogoTitle
                customerLogoList: customerLogoListCollection(
                  preview: ${isPreviewMode}
                  limit: 8
                ) {
                  items {
                    sys {
                      id
                    }
                    contentType
                    url
                    description
                  }
                }
                zapierHookId
              }
            }
          }
          seo {
            title
            description
            ogType
            ogUrl
            ogImage {
              contentType
              url
            }
            ogTitle
            ogDescription
            robots
          }
          ${rightSectionList(isPreviewMode)}
        }
      }
    }
  `;

  const response = await callContentful(
    minifyQuery(query),
    isPreviewMode,
    `/companies/${slug}`,
    'companyDirectory.fetchers.getCompanyDirectoryPage',
  );
  const pageData = pageDataModify<ICompanyDirectory>(
    response.data?.companyDirectoryCollection?.items?.filter(Boolean)?.[0],
  );
  if (!pageData) return null;

  const foundDataSection = pageData.sectionList?.filter(
    (section) => section.__typename === ContentTypeName.CarrierSection,
  ) as IDataSection[];

  if (foundDataSection.length > 0) {
    await Promise.all(
      foundDataSection.map(async (dataSection) => {
        if (dataSection.chartList?.length) {
          dataSection.chartList = await Promise.all(
            (dataSection.chartList || []).map(async (chart) => {
              const query = mapChartQueryOutput(chart.type, chart.query);

              return {
                ...chart,
                query,
                data: await callPmAnalytics({
                  query,
                  accessToken,
                }),
              };
            }),
          );
        }
      }),
    );
  }

  return pageData;
};
