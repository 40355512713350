import { ContentTypeName } from '@/constants';
import { dataSectionQuery, fetchRetailerDetails, rightSectionList } from '@/fetchers';
import {
  type IChart,
  type IDataSection,
  type IPageOverviewBanner,
  type IRetailer,
  type IThumbnailCarousel,
  KeysCanBeCompared,
  PmAnalyticsQueryTable,
  SectionItemType,
  type ThumbnailProps,
  Widget,
} from '@/interfaces';
import { mapRetailerPageOverviewBanner } from '@/mappers';
import { mapChartQueryOutput, mapHeadquartersToQuery, minifyQuery, pageDataModify } from '@/utils';
import callPmAnalytics from '../pmAnalyticsAPI/base.fetchers';
import {
  getCompetitors,
  getRetailerHeadquartersLink,
  needEventOrArticleData,
  replaceDataSectionPlaceholders,
  replaceSeoPlaceholders,
  utils_getEventThumbnailsByDate,
  utils_getThumbnailsByTag,
} from '../utils';
import callContentful from './base.fetchers';

const THUMBNAIL_CAROUSEL_MAX_ITEMS = 30;

type GetRetailerPage = (args: {
  isPreviewMode: boolean;
  slug?: string;
  allEventsList: ThumbnailProps[];
  allArticlesList: ThumbnailProps[];
  accessToken?: string;
}) => Promise<IRetailer | null>;

export const getRetailerPage: GetRetailerPage = async ({
  isPreviewMode,
  slug,
  allEventsList,
  allArticlesList,
  accessToken,
}) => {
  if (!slug) return null;

  const queryFilter = `retailers_contains_some: ["${slug}"]`;

  const query = /* GraphQL */ `
    {
      retailerCollection(
        preview: ${isPreviewMode}
        where: {
          ${queryFilter}
        }
        limit: 1
      ) {
        items {
          sys { id }
          breadcrumbsTheme
          sectionList: sectionListCollection(
            preview: ${isPreviewMode}
            limit: 11
          ) {
            items {
              __typename
              ${dataSectionQuery(isPreviewMode)}
              ... on LeadGenBanner {
                title
                titleMobile
                image {
                  contentType
                  url(transform: { width: 696, resizeStrategy: FIT })
                  description
                }
                imageMobile {
                  contentType
                  url(transform: { width: 696, resizeStrategy: FIT })
                  description
                }
                subCopy
                subCopyMobile
                buttons: buttonsCollection(limit: 3) {
                  items {
                    sys {
                      id
                    }
                    primaryButtonText
                    secondaryButtonText
                    function
                    color
                    email
                    buttonLink
                    buttonEventLabel
                    openInNewTab
                  }
                }
                sectionTheme: theme
                isLinkedInBanner
              }
              ... on LeadsWrapper {
                title
                backgroundImage {
                  contentType
                  url
                  description
                }
                form {
                  sys {
                    id
                  }
                  title
                  subText
                  button {
                    primaryButtonText
                    secondaryButtonText
                    function
                    color
                    buttonLink
                    openInNewTab
                  }
                  formFieldList: formFieldListCollection(
                    preview: ${isPreviewMode}
                    limit: 10
                  ) {
                    items {
                      ... on FormField {
                        name
                        label
                        placeholder
                        isMandatory
                        type
                        width
                        mandatoryCheckErrorMessage
                        customErrorMessage
                        dropdownOptions
                      }
                    }
                  }
                }
                valuePropositionList: valuePropositionListCollection(
                  preview: ${isPreviewMode}
                  limit: 3
                ) {
                  items {
                    sys {
                      id
                    }
                    title
                    contentType
                    url
                    description
                  }
                }
                zapierHookId
              }
              ... on SeoSection {
                title
                seoList
                width
                longForm {
                  json
                }
              }
              ... on ThumbnailCarousel {
                tag {
                  title
                  type
                  slug
                  hide
                }
                itemType
              }
              ... on PageOverviewBanner {
                title
                titleMobile
                subTitle
                subText
                subTextMobile
                buttons: buttonsCollection(limit: 3) {
                  items {
                    sys {
                      id
                    }
                    primaryButtonText
                    secondaryButtonText
                    function
                    color
                    buttonLink
                    email
                    openInNewTab
                    hideOnLoggedIn
                  }
                }
                theme
                image {
                  contentType
                  url
                  description
                }
                imageMobile {
                  contentType
                  url
                  description
                }
                loginTitle
                loginMobileTitle
                loginSubtext
                widget
              }
            }
          }
          seo {
            title
            description
            ogType
            ogUrl
            ogImage {
              contentType
              url
            }
            ogTitle
            ogDescription
            robots
          }
          ${rightSectionList(isPreviewMode)}
        }
      }
    }
  `;

  const response = await callContentful(
    minifyQuery(query),
    isPreviewMode,
    `/retailers/${slug || ''}`,
    'retailer.fetchers.getRetailerPage',
  );
  const pageData = response.data?.retailerCollection?.items?.filter(Boolean)?.[0];
  const sectionItems = pageData?.sectionList?.items?.filter(Boolean);

  if (!pageData) return null;

  //#region pm_insights: Get Retailer Details
  if (!accessToken) return null;

  const retailer = await fetchRetailerDetails({ slug, accessToken });
  if (!retailer) return null;
  //#endregion

  //#region Replace Outer Placeholders Logic
  const replacedPlaceholders = replaceSeoPlaceholders({
    pageData,
    retailer,
  });

  pageData.seo.title = replacedPlaceholders.title;
  pageData.seo.description = replacedPlaceholders.description;
  pageData.seo.ogTitle = replacedPlaceholders.ogTitle;
  pageData.seo.ogDescription = replacedPlaceholders.ogDescription;
  pageData.seo.ogUrl = replacedPlaceholders.ogUrl;
  //#endregion

  //#region Section Logic
  let foundPageOverviewBanner: IPageOverviewBanner[] = sectionItems?.filter(
    (section: any) => section.__typename === ContentTypeName.PageOverviewBanner,
  ) as IPageOverviewBanner[];

  const foundThumbnailCarousel: IThumbnailCarousel[] = sectionItems?.filter(
    (section: any) => section.__typename === ContentTypeName.ThumbnailCarousel,
  ) as IThumbnailCarousel[];

  const foundDataSection: any[] = sectionItems?.filter(
    (section: any) => section.__typename === ContentTypeName.CarrierSection,
  ) as IDataSection[];

  const { hasEvents, hasArticles } = needEventOrArticleData({
    foundThumbnailCarousel,
  });

  const allEvents = hasEvents ? allEventsList || [] : [];
  const allArticles = hasArticles ? allArticlesList || [] : [];

  if (foundPageOverviewBanner) {
    foundPageOverviewBanner = foundPageOverviewBanner.map((item) => {
      if (item.widget !== Widget.InfoBoard) return item;

      return mapRetailerPageOverviewBanner({
        retailer,
        pageOverviewBanner: item,
      });
    });
  }

  if (foundThumbnailCarousel.length > 0) {
    foundThumbnailCarousel.map((item) => {
      const el =
        item.itemType === SectionItemType.Event
          ? utils_getEventThumbnailsByDate({
              events: allEvents,
              slug: item.tag.slug,
            })
          : utils_getThumbnailsByTag({
              articles: allArticles,
              slug: item.tag.slug,
            });
      item.thumbnails = el.slice(0, THUMBNAIL_CAROUSEL_MAX_ITEMS);
    });
  }

  if (foundDataSection.length > 0) {
    await Promise.all(
      foundDataSection.map(async (dataSection) => {
        const dataSectionHref = getRetailerHeadquartersLink({
          headquarters: retailer.headquarters,
          sectionLabel: dataSection.sectionLabel,
        });
        dataSection.href = dataSectionHref;

        // Example: Data Section of {{retailer}} or Data Section of {{headquarters}}
        const replacedTitle = replaceDataSectionPlaceholders(dataSection, retailer);
        dataSection.title = replacedTitle;

        const competitorList = await getCompetitors({
          slug,
          accessToken,
          dataSection,
        });
        dataSection.retailerList = competitorList;

        dataSection.chartList.items = await Promise.all(
          (dataSection.chartList?.items || []).map(async (chart: IChart) => {
            const query = mapChartQueryOutput(chart.type, chart.query);
            const modifiedQuery = mapHeadquartersToQuery(query, retailer.headquarters ?? '');

            const RETAILER_DATA_TABLES = [
              PmAnalyticsQueryTable.RetailerOrderVolume,
              PmAnalyticsQueryTable.RetailerTradelane,
              PmAnalyticsQueryTable.RetailerCarrier,
            ];

            if (RETAILER_DATA_TABLES.includes(query.table)) {
              modifiedQuery.input.push({
                name: KeysCanBeCompared.retailerSlug,
                value: slug,
              });
            }

            if (query.table === PmAnalyticsQueryTable.RetailerCompetitor) {
              // NOTE: No need to delete `limit` since it won't affect the result
              const competitorListData = await Promise.all(
                competitorList.map(async (competitor) => {
                  if (!competitor.slug) return null;

                  const competitorQuery = {
                    ...query,
                    input: [
                      ...query.input,
                      {
                        name: KeysCanBeCompared.retailerSlug,
                        value: competitor.slug,
                      },
                    ],
                  };

                  return await callPmAnalytics({
                    query: competitorQuery,
                    accessToken,
                  });
                }),
              );

              const flattenedData = competitorListData.filter(Boolean).flat();

              return {
                ...chart,
                query,
                data: flattenedData,
              };
            }

            return {
              ...chart,
              query,
              data: await callPmAnalytics({
                query: modifiedQuery,
                accessToken,
              }),
            };
          }),
        );
      }),
    );
  }
  //#endregion

  const modifiedData = pageDataModify(pageData);

  return {
    ...modifiedData,
    ...retailer,
  } as IRetailer;
};
